import React, {Component} from 'react';
import {Tab, Tabs, TabPanel, TabList} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Navbar from '../../components/Navbar';
import {getAuth} from "firebase/auth";
import {collection, getDocs, query, where, doc, getDoc} from "firebase/firestore";
import {db} from "../../firebase/db";
import {Navigate} from 'react-router-dom';
import Accordion from "react-bootstrap/Accordion";
import {useAuth} from "../../firebase/useAuth";
import { Lines } from 'react-preloaders';

class ClientDash extends Component {
    constructor(props){
        super(props);

        this.state = {
            raceData:[],
            gutData:[],
            resourceData: [],
            tasks: [],
            sweatData: [],
            customer_active: 'inactive',
        }

        this.currentDate = 0;

        this.componentDidMount = this.componentDidMount();

        //const auth = getAuth();
        //const user = auth.currentUser;


    }

    componentDidMount = async () => {

        const auth = getAuth();
        const user = auth.currentUser;
        if (user != null) {
            const {raceData} = this.state;
            const {gutData} = this.state;

            const user_id = user.uid;

            let date = new Date();
            let month = date.getMonth();
            let day = date.getDay();
            let year = date.getFullYear();

            this.currentDate = month + '-' + day + '-' + year;

            this.currentDate = date.getTime();

            const q = query(collection(db, "users", user_id, 'race-plan'));

            const raceDocs = await getDocs(q);

            raceDocs.forEach((doc) => {

                var raceDate = doc.data().race_date;

                raceDate = new Date(raceDate);

                raceDate = raceDate.getTime();

                let week = 7 * 24 * 60 * 60 * 1000;

                var race_week = 'Ten Weeks Out';

                var upcoming_week = 'Nine Weeks Out'

                console.log(this.currentDate);
                console.log(raceDate);

                if(this.currentDate < raceDate) {
                    switch (true) {
                        case this.currentDate > raceDate - (week * 10) && this.currentDate < raceDate - (week * 9):
                            race_week = 'Ten Weeks Out';
                            upcoming_week = 'Nine Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 9) && this.currentDate < raceDate - (week * 8):
                            race_week = 'Nine Weeks Out';
                            upcoming_week = 'Eight Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 8) && this.currentDate < raceDate - (week * 7):
                            race_week = 'Eight Weeks Out';
                            upcoming_week = 'Seven Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 7) && this.currentDate < raceDate - (week * 6):
                            race_week = 'Seven Weeks Out';
                            upcoming_week = 'Six Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 6) && this.currentDate < raceDate - (week * 5):
                            race_week = 'Six Weeks Out';
                            upcoming_week = 'Five Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 5) && this.currentDate < raceDate - (week * 4):
                            race_week = 'Five Weeks Out';
                            upcoming_week = 'Four Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 4) && this.currentDate < raceDate - (week * 3):
                            race_week = 'Three Weeks Out';
                            upcoming_week = 'Two Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 3) && this.currentDate < raceDate - (week * 2):
                            race_week = 'Two Weeks Out';
                            upcoming_week = 'One Week Out';
                            break;
                        case this.currentDate > raceDate - (week * 2) && this.currentDate < raceDate - week:
                            race_week = 'One Week Out';
                            upcoming_week = 'Your Race is Next Week';
                            break;
                        case this.currentDate > raceDate - week && this.currentDate <= raceDate:
                            race_week = 'Get Ready for Your Race';
                            break;
                        default:
                            race_week = 'Training Upcoming';
                            break;
                    }

                    let raceObj = {
                        ['race_id']: doc.id,
                        ['race_name']: doc.data().race_name,
                        ['race_date']: doc.data().race_date,
                        ['race_week']: race_week,
                        ['upcoming_week']: upcoming_week,
                        ['products']: doc.data().products
                    }

                    this.state.raceData.push(raceObj)
                }
            });

            this.setState({raceData});

            console.log(user_id)

            const gutDocs = await getDocs(collection(db, "users", user_id, 'gut-training'));

            gutDocs.forEach((doc) => {

                let raceDate = doc.data().race_details.date;

                raceDate = new Date(raceDate);

                raceDate = raceDate.getTime();

                let week = 7 * 24 * 60 * 60 * 1000;

                var race_week = 'Ten Weeks Out';

                var upcoming_week = 'Nine Weeks Out'


                if(this.currentDate < raceDate) {
                    switch (true) {
                        case this.currentDate > raceDate - (week * 10) && this.currentDate < raceDate - (week * 9):
                            race_week = 'Ten Weeks Out';
                            upcoming_week = 'Nine Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 9) && this.currentDate < raceDate - (week * 8):
                            race_week = 'Nine Weeks Out';
                            upcoming_week = 'Eight Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 8) && this.currentDate < raceDate - (week * 7):
                            race_week = 'Eight Weeks Out';
                            upcoming_week = 'Seven Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 7) && this.currentDate < raceDate - (week * 6):
                            race_week = 'Seven Weeks Out';
                            upcoming_week = 'Six Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 6) && this.currentDate < raceDate - (week * 5):
                            race_week = 'Six Weeks Out';
                            upcoming_week = 'Five Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 5) && this.currentDate < raceDate - (week * 4):
                            race_week = 'Five Weeks Out';
                            upcoming_week = 'Four Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 4) && this.currentDate < raceDate - (week * 3):
                            race_week = 'Four Weeks Out';
                            upcoming_week = 'Two Weeks Out';
                            break;
                        case this.currentDate > raceDate - (week * 3) && this.currentDate < raceDate - (week * 2):
                            race_week = 'Three Weeks Out';
                            upcoming_week = 'One Week Out';
                            break;
                        case this.currentDate > raceDate - (week * 2) && this.currentDate < raceDate - week:
                            race_week = 'Two Weeks Out';
                            upcoming_week = 'Your Race is Next Week';
                            break;
                        case this.currentDate > raceDate - week && this.currentDate < raceDate - week:
                            race_week = 'Get Ready for Your Race';
                            break;
                        default:
                            race_week = 'Training Upcoming';
                            break;
                    }


                    let gutObj = {
                        ['gut_id']: doc.id,
                        ['race_id']: doc.data().race_details.id,
                        ['race_name']: doc.data().race_details.race_name,
                        ['race_date']: doc.data().race_details.race_date,
                        ['race_week']: race_week,
                        ['upcoming_week']: upcoming_week,
                        ['products']: doc.data().products

                    }

                    this.state.gutData.push(gutObj)

                }
            });

            this.setState({gutData});



            // initialize product state
            const {resourceData} = this.state;

            // update the gut training per param ID
            const resourceSnap = await getDocs(collection(db, 'resources'));
            resourceSnap.forEach((doc) => {
                let resourceObj = {
                    id:doc.id,
                    title:doc.data().title,
                    content:doc.data().content,
                }

                this.state.resourceData.push(resourceObj);

            });

            this.setState({resourceData});

// initialize product state
            const {sweatData} = this.state;

            const sweatSnap = await getDocs(collection(db, "users", user_id, 'sweat-test'));
            sweatSnap.forEach((doc) => {
                console.log(doc);
                let sweatObj = {
                    id:doc.id
                }

                this.state.sweatData.push(sweatObj);

            });

            this.setState({sweatData})


            //const customer_stripe = await getDocs(doc(db, 'customers', user.uid));
            const customer_sub = await getDocs(collection(db, 'customers', user.uid, 'subscriptions'));

            if(customer_sub) {
                customer_sub.forEach((sub) => {
                    console.log(sub.data())
                    this.setState({customer_active: sub.data().status})
                    console.log(sub.data().status)
                })
            }else{
                this.setState({customer_active: 'inactive'})
            }
            console.log(this.state.customer_active)


        }


    }

    raceNotificationModule = () => {
        console.log(this.state.raceData);

        if(this.state.raceData.length > 0) {

        }else{
            return (

                    <div className="dash-module-alert"><h5> <a href="/client-dashboard/race-plans">Step 1 - input your first race</a></h5></div>

            )
        }



    }

    sweatNotificationModule = () => {
        console.log(this.state.sweatData.length);
        if(this.state.sweatData.length > 0) {
            if(this.state.sweatData.length < 3) {
                return (


                        <div className="dash-module-alert"><h5>You have less than 3 sweat tests, please add at least 3</h5></div>


                )
            }

        }else{
            return (

                    <div className="dash-module-alert"><h5><a href="/client-dashboard/sweat-testing/">Step 2 - start collecting sweat test data</a></h5></div>

            )
        }

    }


    gutNotificationModule = () => {
        console.log(this.state.gutData.products);
        if(this.state.gutData.length <= 0) {

            return (

                <div className="dash-module-alert"><h5><a href="/client-dashboard/gut-training/">Step 3 - select your products for your gut training</a></h5></div>

            )
        }else{
            this.state.gutData.forEach((result) => {
                console.log(result.products);
                if(result.products.length <= 0) {

                     this.notification = <div className="dash-module-alert"><h5><a href="/client-dashboard/gut-training/">Step 3 - select your products for your gut training</a></h5></div>;

                }

            });
            return this.notification;
        }

    }

    gutTaskModule = () => {

        if(this.state.gutData.length > 0) {
                return (

                    <div className="dash-module-content">

                        {
                            this.state.gutData.map((result) => {
                                console.log(result);
                                let race_id = result.race_id;
                                let gut_url = "/client-dashboard/gut-training/details/" + result.gut_id;
                                let race_url = "/client-dashboard/race/details/" + result.race_id;
                                    return (

                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>{result.race_name}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <a href={gut_url}> Gut Training </a>
                                                        - {result.race_week}<br/>
                                                        <div className="dash-race-week-data">
                                                            <div className="dash-current-product-title">Current Products</div>
                                                            <ul className="dash-current-products">

                                                                {result.products.map((products, index) => {
                                                                    return (
                                                                        <li>{result.products[index].name}</li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>


                                    )

                            })
                        }


                    </div>

                )

        }else{
            return(
                <div className="dash-module-content h-100">
                    <h3>No current or upcoming tasks</h3>
                </div>
            )
        }



    }


    render() {
        console.log(this.state.customer_active);
        if(this.state.customer_active === 'active') {
            return (

                <div className="client-dash-container">
                    <Navbar/>
                    <div className="col client-dash-modules container-xxl">
                        <div className="row row-cols-2">
                            <div className="col-md-6 m-auto">
                                <div className="row">
                                    <div className="col dash-module-single">
                                        <div className="dash-module-title-container">
                                            <span className="dash-module-title">Tasks</span>
                                        </div>
                                        {this.gutTaskModule()}

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 m-auto">
                                <div className="row">
                                    <div className="col dash-module-single">
                                        <div className="dash-module-title-container">
                                            <span className="dash-module-title">Notifications</span>
                                        </div>
                                        <div className="dash-module-content">
                                            {this.raceNotificationModule()}
                                            {this.sweatNotificationModule()}
                                            {this.gutNotificationModule()}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            );
        }else {
            return (
            <div className="client-dash-container">
                <div className="col client-dash-modules container-xxl">
                    <div className="row row-cols-2">
                        <div className="col-md-6 m-auto">
                            <div className="row">
                                <div className="col dash-module-single">
                                    <div className="dash-module-title-container">
                                        <p>You are not authorized.</p>
                                        <p>Please Sign up for a Subscription <a href="/client-dashboard/account"> Here</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }

}


export default ClientDash;