import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState, useForm } from 'react-hook-form';
import { loginTab } from './Login.js';
import {Tab, Tabs, TabPanel, TabList} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {useNavigate, Navigate} from 'react-router-dom';
import { } from "../firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleCheck, faDownLong, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

function withNavigation(Component : Component){
    return props => <Component {...props} navigate={useNavigate()} />;
}

class Login extends Component {
    constructor(props) {
        super(props);


        this.state = {
            emailValue: '',
            passwordValue: '',
            passwordInput: 'password',
            passwordInputID: 'password-view',
            eyeIcon: <FontAwesomeIcon icon={faEye}/>
        }

        this.onSignInClicked = this.onSignInClicked.bind(this);
        this.onSignInWithGoogleClicked = this.onSignInWithGoogleClicked.bind(this);
        this.practitionerLogin = this.practitionerLogin.bind(this);
        this.emailValueInput = this.emailValueInput.bind(this);
        this.passwordValueInput = this.passwordValueInput.bind(this);
        this.passwordToggle = this.passwordToggle.bind(this);
    }

    componentDidMount(){
        const auth = getAuth();
        const user = auth.currentUser;
        if(user != null){
            this.setState({ redirectTo: '/client-dashboard' });
        }

    }

    emailValueInput(event){
        this.setState({emailValue:event.target.value});
    }
    passwordValueInput(event){
        this.setState({passwordValue:event.target.value});
    }


    onSignInClicked = async (event) => {
        event.preventDefault();
        const auth = getAuth();

        signInWithEmailAndPassword(auth, this.state.emailValue, this.state.passwordValue)
            .then((userCredential) => {
            const user = userCredential.user;
            console.log(user);
            this.setState({ redirectTo: '/client-dashboard' });

            })
            .catch((error) => {
                const errorCode = error.code
            });


    }

    onSignInWithGoogleClicked = async () => {
        // Firebase code goes here
    }

    practitionerLogin = async () => {
        //this.history('/practitioner-dashboard/')
    }

    handleChange = async (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    passwordToggle = (e) => {
        let current_view = e.currentTarget.id;

        if(current_view === 'password-view'){
            this.setState({passwordInputID: 'text-view'});
            this.setState({passwordInput: 'text'});
            this.setState({eyeIcon: <FontAwesomeIcon icon={faEyeSlash}/>});
        }else{
            this.setState({passwordInputID: 'password-view'});
            this.setState({passwordInput: 'password'});
            this.setState({eyeIcon: <FontAwesomeIcon icon={faEye}/>});
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={"/client-dashboard/"} />;
        }
        return (
            <div className="pageContainer">
                <div className="login-container">
                    <Tabs className="login-tabs">
                        <TabList>
                            <Tab>Client Login</Tab>
                            <Tab>Practitioner Login</Tab>
                        </TabList>

                        <TabPanel>
                            <form className="login-fields" onSubmit={this.onSignInClicked}>
                                <label htmlFor="email">Email Address or Username</label><br/>
                                <input id="email" type="text" name="email"
                                       value={this.state.email}
                                       onChange={this.emailValueInput}/> <br/><br/>
                                <div className="password-toggle">
                                <label htmlFor="password">Password</label><br/>
                                    <input id="password" type="password" name="password" value={this.state.password}
                                           onChange={this.passwordValueInput}
                                    />
                                    <span className="password-toggle-icon" id={this.state.passwordInputID} onClick={this.passwordToggle}>{
                                        this.state.eyeIcon}</span>
                                </div>
                                <div className="forgot-pass">
                                    <span><a href="/reset/">Forgot your password?</a></span>
                                </div>
                                <button className="login-button"> Log In
                                </button>
                            </form>
                        </TabPanel>
                        <TabPanel>
                            <button className="login-button" onClick={this.practitionerLogin}>Log In</button>
                        </TabPanel>
                    </Tabs>

                    <div className="register">
                        <span><a href="/signup">Register</a></span>
                    </div>
                </div>
            </div>
        );
    }

}




export default withNavigation(Login);